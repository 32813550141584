import React, { useState, useEffect, useContext } from "react";

import { Box, Slider } from "@material-ui/core";
import "./search.css";
import Dropdown from "../ReusableComponent/Dropdown";
import LocationSearch from "../ReusableComponent/LocationSearch";
import { getFilter, searchVehicle } from "../api";
import SearchCar from "./SearchCar";
import ModalReusable from "../ReusableComponent/ModalReusable";
import { DataContext } from "../ContextApi";
import Instantbooking from "../component/Instantbooking";
import NumberOfSeats from "../component/NumberOfSeats";
import facebook from "../image/svg/keyhand.svg";
import Features from "../component/Features";
import FuelType from "../component/FuelType";
import CarMake from "../component/CarMake";
import Transmission from "../component/Transmission";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes, startOfToday } from "date-fns";
import pickup from ".././image/png/pickup.svg";
import drop from ".././image/png/drop.svg";

import {
  handleAllFilters,
  handleDate,
  handleOverAllFilter,
} from "../ReusableComponent/OverAllFilter";
import { formatDate } from "../ExportFiles";


const SearchDriver = () => {
  const {
    filterValue,
    setFilterValue,
    searchVehicles,
    setSearchVehicles,
    setLoader,formData,
    isFilterActive,
    openDialog,
    setIsFilterActive,
    
  } = useContext(DataContext);

  const [open, setOpen] = useState(false);
const [resultArray,setResultArray]=useState()
const [filterSelect,setFilterSelect]=useState(false)

  const [allFilter, setAllFilter] = useState([]);
  const handleSeach = () => {
    setLoader(true);
    setFilterSelect(true);
    searchVehicle(filterValue, setSearchVehicles, setLoader,setResultArray,openDialog);
    handleClose();
    // setFilterValue({
    //   ...filterValue,

    //   instantBooking: false,
    //   numberSeats: 0,
    //   newCarOnly: false,
    //   features: [],
    //   transmission: {},
    //   fuelType: [],
    //   carMake: [],
    //   acceptRate: false,
    // });
  };
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleOpen = () => {
    setOpen(true); // Open the modal
    setFilterSelect(false)
  };


  console.log("startDate", searchVehicles);
  const handleClose = () => {
    setOpen(false);

    // setFilterValue({
    //   ...filterValue,
    //   vehicleType: [],
    //   driverOption: {},
    //   instantBooking: false,
    //   numberSeats: 5,
    //   newCarOnly: false,
    //   features: [],
    //   transmission: {},
    //   fuelType: [],
    //   carMake: [],
    //   acceptRate: false,
    //   price: 30,
    // });
  };

  const handleSelect = (value) => {
    if (value === "price") {
      setIsFilterActive({ ...isFilterActive, priceFilterActive: true });
    } else if (value === "vehicleType") {
      setIsFilterActive({ ...isFilterActive, vehicleTypeFilter: true });
    } else if (value === "pickup") {
      setIsFilterActive({ ...isFilterActive, pickupFilter: true });
    }
    setLoader(true);
    searchVehicle(filterValue, setSearchVehicles, setLoader,setResultArray,openDialog);
    // Handle other actions based on the selected option
  };

  useEffect(() => {
    getFilter(setAllFilter);
    const storedPickDateTime = sessionStorage.getItem("pick_up_date_time");
    const storedDropDateTime = sessionStorage.getItem("drop_off_date_time");

    if (storedPickDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        pick_up_date_time: storedPickDateTime,
      }));
    }
    if (storedDropDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        drop_off_date_time: storedDropDateTime,
      }));
    }
    const location = sessionStorage.getItem("location");
    if (location) {
      // Parse the JSON string back into an object
      const value = JSON.parse(location);
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        lat: value.latLng.lat,
        lng: value.latLng.lng,
      }));
    }
  }, []);
  const handlePriceChange = (val) => {
    setFilterValue({ ...filterValue, price: val });
  };
  const defaultValues = {
    instantBooking: false,
    numberSeats: 0,
    newCarOnly: false,
    features: [],
    transmission: {},
    fuelType: [],
    carMake: [],
    acceptRate: false
};


function countSpecificNonDefaultValues(current) {
  const keysToCheck = [
      'instantBooking',
      'numberSeats',
      'newCarOnly',
      'features',
      'transmission',
      'fuelType',
      'carMake',
      'acceptRate'
  ];

  let count = 0;

  keysToCheck.forEach(key => {
      if (current.hasOwnProperty(key)) {
          if (Array.isArray(current[key])) {
              // Check if the array is non-empty
              if (current[key].length > 0) {
                  count++;
              }
          } else if (typeof current[key] === 'object' && current[key] !== null) {
              // Check if the object is non-empty
              if (Object.keys(current[key]).length > 0) {
                  count++;
              }
          } else if (current[key] !== defaultValues[key]) {
              // For primitive values, check if they differ from defaults
              count++;
          }
      }
  });
  
  return count;
}
  return (
    <body style={{ height: "auto" }} id="search_show">
      <div class="js_site_content site_content">
        <div class="js_search-revamp-2023 search_container" id="js_picks">
          <>
            <svg style={{ display: "none" }} xmlns="http://www.w3.org/2000/svg">
              <defs>
                <symbol
                  id="svg_icons-marker"
                  class="svg_from_sprite"
                  viewBox="0 0 24 24"
                >
                  <g fill-rule="evenodd" stroke="none">
                    <path d="M19,10 C19,15.0914792 13.07625,21.0728333 12.8254167,21.3249792 C12.5964583,21.5527708 12.2989583,21.6666667 12,21.6666667 C11.7010417,21.6666667 11.4035417,21.5527708 11.1745833,21.3249792 C10.9222917,21.0728333 5,15.0914792 5,10 C5,6.14022917 8.13979167,3 12,3 C15.8602083,3 19,6.14022917 19,10 Z M12,13.5 C13.9322917,13.5 15.5,11.9330208 15.5,10 C15.5,8.067125 13.9322917,6.5 12,6.5 C10.0677083,6.5 8.5,8.067125 8.5,10 C8.5,11.9330208 10.0677083,13.5 12,13.5 Z"></path>
                  </g>
                </symbol>
              </defs>
            </svg>
            <div class="picks_search_box" id="js_search_form">
              <div class="container">
                <div
                  style={{ display: "none" }}
                  class="c-px-xs c-pt-2xs c-pb-sm c-flex c-content-between c-items-center md:c-hidden"
                >
                  <div class="c-text-title-sm c-text-base c-flex-1">
                    Edit your search
                  </div>
                  <div class="cobalt-Icon js_close_search_form_on_mobile">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-5">
                    <div class="address_field relative js_address_field js_AddressAutocomplete-container">
                      <div class="Address-autocomplete Address-autocomplete--powered-by-google">
                        <div
                          class="cobalt-Autocomplete"
                          data-reach-combobox=""
                          data-state="suggesting"
                        >
                          <div class="cobalt-Autocomplete__input-wrapper">
                            <div class="cobalt-TextField cobalt-TextField--withIcon">
                              <LocationSearch
                                value={true}
                                setSearchVehicles={setSearchVehicles}
                                searchPage={true}
                              />

                              <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                </svg>
                              </span>
                            </div>
                            {/* <div
                            class="cobalt-Autocomplete__clear-button"
                            role="clear"
                          >
                            <span class="cobalt-Icon cobalt-Icon--close cobalt-Icon--size16">
                              <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"
                                ></path>
                              </svg>
                            </span>
                          </div> */}
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{display:"none"}} class="bottom10_xs col-lg-6 col-md-7 col-sm-12">
                    <div class="datetime_range_wrapper js_datetime_range">
                      <div class="datetime_range_input js_datetime_range_input is_layout_search">
                        <div
                          class="datetime_input_container js_datetime_input"
                          data-bound="end"
                          aria-expanded="false"
                        >
                          <div
                            class="datetime_input_date_wrapper  js_date_input"
                            data-bound="end"
                            style={{position:"relative"}}
                          >
                            <DatePicker
                              selected={
                                filterValue?.pick_up_date_time
                                  ? new Date(filterValue.pick_up_date_time)
                                  : null
                              }
                              onChange={(e) => {
                                handleDate(
                                  e,
                                  setFilterValue,
                                  filterValue,
                                  "pick_up_date_time",
                                  openDialog,
                                  handleSeach
                                );
                                // if(filterValue?.drop_off_date_time)
                                // {
                                //   handleSeach();

                                // }
                              }}
                              popperPlacement="bottom-start" // Ensures calendar opens at the right place
    portalId="root-portal" // Ensure you have a div with this id in your HTML
                              minDate={startOfToday()}
                              showTimeSelect
                              placeholderText="Pickup"
                              excludeTimes={[
                                setHours(setMinutes(new Date(), 0), 17),
                                setHours(setMinutes(new Date(), 30), 18),
                                setHours(setMinutes(new Date(), 30), 19),
                                setHours(setMinutes(new Date(), 30), 17),
                              ]}
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
 <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                  <div  style={{background:"none"}} class="cobalt-bg-icon">
                                    <img
                                      src={pickup}
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        }}
                                      alt="pickup"
                                    />
                                  </div>
                                </div>
                          </div>
                        </div>
                        <div
                          class="datetime_input_container js_datetime_input"
                          data-bound="end"
                          aria-expanded="false"
                        >
                          <div
                          style={{position:"relative"}}
                            class="datetime_input_date_wrapper  js_date_input"
                            data-bound="end"
                          >
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <CustomDateTimePicker
                                  name="drop_off_date_time"
                                  onChange={(newValue) => {
                                    handleDate(
                                      newValue,
                                      setFilterValue,
                                      filterValue,
                                      "drop_off_date_time",
                                      dayjs
                                    );
                                  }}
                                  disabled={!filterValue?.pick_up_date_time}
                                  value={
                                    parseDateTime(
                                      filterValue?.drop_off_date_time
                                    ) || null
                                  }
                                  minutesStep={30}
                                  minDateTime={
                                    parseDateTime(
                                      filterValue?.pick_up_date_time
                                    ) || dayjs()
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider> */}
                            <DatePicker
                              selected={
                                filterValue?.drop_off_date_time
                                  ? new Date(filterValue.drop_off_date_time)
                                  : null
                              }
                              minDate={
                                filterValue?.pick_up_date_time
                                  ? new Date(filterValue.pick_up_date_time)
                                  : new Date()
                              }
                              onChange={(newValue) => {
                                handleDate(
                                  newValue,
                                  setFilterValue,
                                  filterValue,
                                  "drop_off_date_time",
                                  openDialog,handleSeach
                                );

                              }}
                              disabled={                                  formData?.carService==="4"|| formData?.carService==="5"
                              }
                              showTimeSelect
                              placeholderText="Return"
                              excludeTimes={[
                                setHours(setMinutes(new Date(), 0), 17),
                                setHours(setMinutes(new Date(), 30), 18),
                                setHours(setMinutes(new Date(), 30), 19),
                                setHours(setMinutes(new Date(), 30), 17),
                              ]}
                              popperPlacement="bottom-start" // Ensures calendar opens at the right place
    portalId="root-portal" // Ensure you have a div with this id in your HTML
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                             <div  class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div style={{background:"none"}}  class="cobalt-bg-icon">
                                  <img
                                    src={drop}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                    }}
                                    alt="drop"
                                  />
                                </div>
                              </div>
                          </div>
                          {/* <div>
                            ahn
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="visible-xs-block c-mt-2xs">
                  <button class="js_close_search_form_on_mobile cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth">
                    Search
                  </button>
                </div>
                <div
                  class="cobalt-Callout cobalt-Callout--error top10 bottom0 js_cart_error_message hidden_content"
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>
            <div
              style={{ display: "none" }}
              class="search_form_mobile_placeholder_container js_search_form_mobile_placeholder_container"
            >
              <div
                class="search_form_mobile_placeholder"
                id="js_search_form_mobile_placeholder"
              >
                <div class="search-box-wrapper">
                  <div class="search_field_placeholder js_search_field_placeholder">
                    <div
                      class="c-text-title-sm c-text-base location_mobile_placeholder"
                      data-empty-placeholder="Rental address"
                      id="js_location_mobile_placeholder"
                    >
                      Chelsea, Manhattan, New York City
                    </div>
                    <strong
                      class="c-text-body-sm c-text-subdued dates_mobile_placeholder"
                      data-empty-placeholder="Select dates"
                      id="js_dates_mobile_placeholder"
                    >
                      Aug 13, 07:00 AM → Aug 24, 06:30 AM
                    </strong>
                  </div>
                  <div class="search_filters_toggle js_toggle_filters">
                    <div class="c-flex">
                      <div class="js_selecter_filters_empty_state cobalt-Icon cobalt-Icon--colorAccent cobalt-Icon--size16">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.002 7A.995.995 0 0 0 2 8c0 .556.449 1 1.002 1h6.124a4.007 4.007 0 0 1 0-2H3.002Zm17.995 0A.999.999 0 0 1 22 8c0 .552-.44 1-1.003 1h-4.123a4.01 4.01 0 0 0 0-2h4.123ZM4.127 15H3.002A.995.995 0 0 0 2 16c0 .556.449 1 1.002 1h1.124a4.007 4.007 0 0 1 0-2Zm16.871 0A.999.999 0 0 1 22 16c0 .552-.44 1-1.003 1h-9.123a4.01 4.01 0 0 0 0-2h9.123ZM13 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          ></path>
                        </svg>
                      </div>
                      <div
                        class="js_selected_filters_counter search_filters__counter"
                        hidden=""
                      >
                        0
                      </div>
                    </div>
                    <div class="c-font-brand">Filters</div>
                  </div>
                </div>
                <div
                  class="cobalt-Callout cobalt-Callout--error top10 bottom0 js_cart_error_message hidden_content"
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>

            <div class="js_filters_modal"></div>
            <div
            style={{display:"none"}}
              class="js_filters-bar filters-bar c-border-b c-border-base"
              data-load-path="/search.json"
              data-path="/search"
            >
              <div class="pills-filters c-gap-xs c-w-full c-flex c-py-sm c-px-md c-overflow-x-auto">
                <div class="c-flex c-gap-xs">
                  <Dropdown
                    options={options}
                    onSelect={() => handleSelect("price")}
                    vehicleType={allFilter?.vehicle_type}
                    heading={"Total price"}
                  >
                    <Box sx={{ width: 300 }}>
                      <div style={{ marginTop: "10px" }}>
                        <p className="method-detail">All Prices</p>
                      </div>

                      <Slider
                        style={{ color: "#ffb400" }}
                        aria-label="Temperature"
                        defaultValue={filterValue?.price}
                        onChange={(event, value) => handlePriceChange(value)} // Add onChange event handler
                        valueLabelDisplay="auto"
                        shiftStep={30}
                        step={allFilter?.price?.interval}
                        marks
                        min={allFilter?.price?.min_price}
                        max={allFilter?.price?.max_price}
                      />
                    </Box>
                  </Dropdown>
                  <Dropdown
                    options={options}
                    onSelect={() => handleSelect("vehicleType")}
                    vehicleType={allFilter?.vehicle_type}
                    heading={
                      isFilterActive.vehicleTypeFilter
                        ? `Vehicle type (${filterValue.vehicleType.length})`
                        : "Vehicle type"
                    }
                  >
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {allFilter?.vehicle_type?.map((val) => (
                        <div
                          style={{
                            backgroundColor: filterValue?.vehicleType?.includes(
                              val
                            )
                              ? "#ffc947"
                              : "",
                          }}
                          onClick={() =>
                            handleAllFilters(
                              "vehicleType",
                              val,
                              setFilterValue,
                              filterValue.vehicleType
                            )
                          }
                          className="drop-down-circle-internal"
                        >
                          <p>{val.vehicle_type}</p>
                        </div>
                      ))}
                    </div>
                  </Dropdown>
                  <Dropdown
                    onSelect={() => handleSelect("pickup")}
                    heading={"Driver option"}
                  >
                    {allFilter?.driver_option?.map((val) => (
                      <div
                        onClick={() =>
                          handleOverAllFilter(setFilterValue, "driverOption", {
                            ...val,
                          })
                        }
                        className="dropdown-method"
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          backgroundColor:
                            filterValue.driverOption.driver_option_id ===
                            val?.driver_option_id
                              ? "#ffc947"
                              : "",
                        }}
                      >
                        <div style={{ display: "flex", gap: "20px" }}>
                          {/* <img
                            style={{ height: "30px" }}
                            src={val?.image_url}
                            alt="location"
                          /> */}
                          <div>
                            {/* <p className="headingRight">{val.pickup_methoc}</p> */}
                            <p className="method-detail">{val.driver_option}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Dropdown>
                </div>
                <div onClick={handleOpen}>
                  <div>
                    <button style={{background:countSpecificNonDefaultValues(filterValue)>0&&filterSelect?"rgb(255, 201, 71)":""}} class="more-filters-button c-whitespace-nowrap with-icon cob-Button cob-Button__default">
                      <span class="cob-Button__Icon cob-Button__Icon--left">
                        <span class="cobalt-Icon cobalt-Icon--slider">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.002 7A.995.995 0 0 0 2 8c0 .556.449 1 1.002 1h6.124a4.007 4.007 0 0 1 0-2H3.002Zm17.995 0A.999.999 0 0 1 22 8c0 .552-.44 1-1.003 1h-4.123a4.01 4.01 0 0 0 0-2h4.123Zm-16.87 8H3.002A.995.995 0 0 0 2 16c0 .556.449 1 1.002 1h1.124a4.007 4.007 0 0 1 0-2Zm16.871 0A.999.999 0 0 1 22 16c0 .552-.44 1-1.003 1h-9.123a4.01 4.01 0 0 0 0-2h9.123ZM13 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cob-Button__content">
                        <div class="c-flex c-items-center c-gap-xs">
                        <div>
  {`More filters ${countSpecificNonDefaultValues(filterValue) > 0 && filterSelect ? `(${(countSpecificNonDefaultValues(filterValue))})` : ""}`}
</div>
                        </div>
                      </span>
                    </button>
                  </div>
                </div>
                <ModalReusable
                  open={open}
                  handleClose={handleClose}
                  handleOperation={handleSeach}
                  buttonText={"Search"}
                  modalHeader={"Filters"}
                  setFilterSelect={setFilterSelect}
                >
                  <div
                    style={{
                      height: "50vh",
                      overflow: "auto",
                      paddingTop: "20px",
                    }}
                  >
                    <div className="modal-content">
                      <Instantbooking
                        icon={facebook}
                        text={"Instant booking"}
                        detail={
                          "Vehicles you can book without waiting for owner approval"
                        }
                      />
                      <NumberOfSeats />
                      <Instantbooking
                        newCar={true}
                        margin={15}
                        text={"New cars only"}
                        detail={"Manufactured in the last 5 years"}
                      />
                      <Features features={allFilter.featues} />
                      <Transmission
                        transmission={allFilter?.transmission_type}
                      />
                      <FuelType fuel={allFilter?.fuel_type} />

                      <CarMake carMake={allFilter.make} />

                      <Instantbooking
                        acceptRate={true}
                        margin={15}
                        text={"Accept rate"}
                        detail={"Above 30%"}
                      />
                    </div>
                  </div>
                </ModalReusable>
              </div>
            </div>

            <SearchCar searchDriver={true} request={searchVehicles?.request} searchVehicles={searchVehicles?.driver_list} />
          </>
        </div>
      </div>
    </body>
  );
};

export default SearchDriver;
