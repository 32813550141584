import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reservation.css";
const CancelationTypeInsurance = () => {
  const { setSelectDataCaseThree, selectDataCaseThree, rentYourCar } =
    useContext(DataContext);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Insurance</div>

        {/* <div class="c-text-section-heading c-mb-xs">Insurance</div> */}

        <div className="c-mb-lg">
          <div
            className="cobalt-CheckmarkField"
            data-form-checkmark-method="is_pro"
          >
            <div>
            {rentYourCar?.insurance_types?.map((val) => (

              <div className="discount-option">
                <input
                  type="checkbox"
                  name="types_insurance"
                      checked={selectDataCaseThree?.types_insurance?.includes(val?.insurance_id)}
                      value={val?.insurance_id}
                      onChange={(e) =>
                        handleChange(
                          e,
                          setSelectDataCaseThree,
                          selectDataCaseThree,
                          "caseThree"
                        )
                      }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">19%</span> */}
                  <div className="discount-info">
                    <strong>{val?.insurance_type}</strong>
                    <p>{val?.insurance_detail}</p>
                  </div>
                </div>
              </div>
           ) )}
             
            </div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default CancelationTypeInsurance;
