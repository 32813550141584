import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./unavailbilty.css";
import ModalReusable from "../../ReusableComponent/ModalReusable";
import pickup from "../../image/png/pickup.svg";
import drop from "../../image/png/drop.svg";
import { useState } from "react";

const Unavailability = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSaveUnavailable,
  highlightUnavailableDates,
  border
}) => {
  // Handle start date change
  const handleStartDateChange = (date) => {
    setStartDate(date);
    // If the end date is before the new start date, reset the end date
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateSelect = (date) => {
    const isDateSelected = selectedDates.some(
      (d) => d.getTime() === date.getTime()
    );

    if (isDateSelected) {
      // Remove the date if it is already selected
      setSelectedDates(selectedDates.filter((d) => d.getTime() !== date.getTime()));
    } else {
      // Add the date if it is not selected
      setSelectedDates([...selectedDates, date]);
    }
  };
console.log("selectedDates",selectedDates)
  // Handle end date change
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className="calendar-container">
      <div
        style={{ overflow: "initial",border:border&&"none" }}
        className="cobalt-Card cobalt-Card--responsive c-mb-sm"
      >
        <div style={{border:border&&"none"}} className="cobalt-Card__Section">
          <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
            {" "}
        {/* {border?"Edit Pick and return Dates":"Add Unavailable Dates:"}     */}
          </h1>
          <div style={{ display: "none", gap: "10px", flexWrap: "wrap" }}>
            <div className="cobalt-FormField">
              <div className="edit-form cobalt-TextField cobalt-TextField--withIcon">
                <label>Start date</label>
                <div
                  style={{
                    position: "relative",
                    display: "inline-grid",
                    width: "100%",
                  }}
                  className="datetime_input_date_wrapper js_date_input "
                  data-bound="end"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Pickup"
                    popperPlacement="bottom-start"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: "viewport", // This ensures the calendar doesn't overflow the viewport
                      },
                    }}
                    minDate={new Date()} // Prevents selection of dates before today
                    maxDate={endDate || null} // Optional, restrict max start date to selected end date
                  />
                  <span
                    style={{ top: "11px" }}
                    className="cobalt-TextField__Icon cobalt-Icon"
                  >
                    <div className="cobalt-bg-icon">
                      <img
                        src={pickup}
                        style={{ width: "24px", height: "24px" }}
                        alt="pickup"
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "auto" }} className="cobalt-FormField">
              <div className="edit-form cobalt-TextField cobalt-TextField--withIcon">
                <label>End date</label>
                <div
                  style={{
                    position: "relative",
                    display: "inline-grid",
                    width: "100%",
                  }}
                  className="datetime_input_date_wrapper js_date_input "
                  data-bound="end"
                >
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Return"
                    popperPlacement="bottom-start"
                    minDate={startDate || new Date()} // Ensure end date is not before start date or today
                  />
                  <span
                    style={{ top: "11px" }}
                    className="cobalt-TextField__Icon cobalt-Icon"
                  >
                    <div className="cobalt-bg-icon">
                      <img
                        src={drop}
                        style={{ width: "24px", height: "24px" }}
                        alt="drop"
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
           {!border&&" Unavailable Cars:"}
          </h1>
          <div>
            {/* <DatePicker
              calendarClassName="full-width-calendars"
              inline
              monthsShown={2} // Show two months side by side
              selected={null}
              dayClassName={highlightUnavailableDates}
            /> */}
            <DatePicker
        selected={null} // Leave this null to not highlight any specific date by default
        onSelect={handleDateSelect} // Called whenever a date is selected
        calendarClassName="full-width-calendars"
        inline
        monthsShown={2}
        highlightDates={selectedDates} // Highlight the selected dates
      />

          </div>
          {/* <div style={{ textAlign: "right", marginTop: "10px" }}>
            <button
              style={{
                width: "70px",
                height: "40px",
                backgroundColor:
                  !startDate || !endDate ? "#ccc" : "rgb(255 180 0)", // Gray when disabled, blue when enabled
                color: !startDate || !endDate ? "#666" : "#fff", // Lighter text when disabled, white when enabled
                cursor: !startDate || !endDate ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
              }}
              className="button-color"
              onClick={handleSaveUnavailable}
              disabled={!startDate || !endDate} // Disable save button if dates are not selected
            >
              Save
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Unavailability;
