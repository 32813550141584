import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import {  TextareaAutosize } from "@mui/material";

const DriverDescription = () => {
  const { rentYourCar, setSelectDriverCase, selectDriverCase } =
    useContext(DataContext);
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        {/* <div class="c-text-title-md c-mb-lg">Any other features?</div> */}
        <div class="c-mb-lg">
          
          <div className="cobalt-TextField">
            <label className="modalLabel" htmlFor="first_name">
              Description
            </label>

            <TextareaAutosize
            style={{paddingLeft:"10px"}}
              name="description"
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDriverCase,
                  selectDriverCase,
                  "caseDriver"
                )
              }
             value={selectDriverCase.description}
            />
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Attract drivers to your listing and gain more bookings 💪
        </div>
      </div>
    </>
  );
};

export default DriverDescription;
