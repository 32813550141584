import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reduction.css";
const DriverPromotion = () => {
  const { setSelectDriverCase, selectDriverCase, rentYourCar } =
    useContext(DataContext);
  console.log("selectDriverCase.discount", selectDriverCase.discount);
  const [selectedDiscounts, setSelectedDiscounts] = useState({
    newPromotion: true,
    weeklyDiscount: true,
    monthlyDiscount: true,
  });
  const [discount, setDiscount] = useState({
    discountOne: "10",
    discountTwo: "15",
    discountThree: "25",
  });
  const handleCheckboxChange = (discountType) => {
    setSelectedDiscounts((prev) => ({
      ...prev,
      [discountType]: !prev[discountType],
    }));
  };

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">Add discounts</div>
        <div class="c-text-title-md c-mb-lg">
          Make your accommodation stand out from the crowd to get reservations
          faster and receive your first reviews
        </div>
        <div className="">
          <div className="c-mb-lg">
            <div
              className="cobalt-CheckmarkField"
              data-form-checkmark-method="is_pro"
            >
              <div>
                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="promotion"
                    checked={selectDriverCase.promotion.includes("1")}
                    value="1"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDriverCase,
                        selectDriverCase,
                        "caseDriver"
                      )
                    }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">
                      <input
                        className="input-discount"
                        type="number"
                        value={discount.discountOne}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "+") {
                            e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only positive numbers
                          if (value >= 0) {
                            setDiscount({ ...discount, discountOne: value });
                            // handleChange(
                            //   e,
                            //   setSelectDataCaseTwo,
                            //   selectDataCaseTwo,
                            //   "caseTwo"
                            // );
                          }
                        }}
                      />
                      %
                    </span>{" "}
                    <div className="discount-info">
                      <strong>Promotion Nouvelles annonces</strong>
                      <p>{`Offer a ${discount?.discountOne}% discount on your first 3 bookings`}</p>
                    </div>
                  </div>
                </div>

                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="promotion"
                    checked={selectDriverCase.promotion.includes("2")}
                    value="2"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDriverCase,
                        selectDriverCase,
                        "caseDriver"
                      )
                    }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">
                      <input
                        className="input-discount"
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "+") {
                            e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                          }
                        }}
                        value={discount.discountTwo}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only positive numbers
                          if (value >= 0) {
                            setDiscount({ ...discount, discountTwo: value });

                            // handleChange(
                            //   e,
                            //   setSelectDataCaseTwo,
                            //   selectDataCaseTwo,
                            //   "caseTwo"
                            // );
                          }
                        }}
                        type="number"
                      />
                      %
                    </span>{" "}
                    <div className="discount-info">
                      <strong>Weekly reduction</strong>
                      <p>For stays of 7 nights or more</p>
                    </div>
                  </div>
                </div>

                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="promotion"
                    checked={selectDriverCase.promotion.includes("3")}
                    value="3"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDriverCase,
                        selectDriverCase,
                        "caseDriver"
                      )
                    }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">
                      <input
                        className="input-discount"
                        value={discount.discountThree}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "+") {
                            e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only positive numbers
                          if (value >= 0) {
                            setDiscount({ ...discount, discountThree: value });

                            // handleChange(
                            //   e,
                            //   setSelectDataCaseTwo,
                            //   selectDataCaseTwo,
                            //   "caseTwo"
                            // );
                          }
                        }}
                        type="number"
                      />
                      %
                    </span>{" "}
                    <div className="discount-info">
                      <strong>Monthly discount</strong>
                      <p>For stays of 28 nights or more</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-mb-2xl">
          <div className="c-mb-lg">
            <div
              className="cobalt-CheckmarkField"
              data-form-checkmark-method="is_pro"
            ></div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default DriverPromotion;
