import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverSkills = () => {
  const { rentYourCar,selectDriverCase, setSelectDriverCase } =
    useContext(DataContext);
  const [selectData, setSelectData] = useState({
    carBrand: "",
    carModel: "",
  });

  useEffect(() => {
    if (rentYourCar?.make?.length > 0 && rentYourCar?.model?.length > 0) {
      setSelectData({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
      });
    }
  }, [rentYourCar]);
console.log("selectDriverCase",selectDriverCase)
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Driver Skills</div>
        <div class="c-mb-lg">
          <div class="cobalt-FormField">
            <label class="cobalt-FormField__Label" for="car_transmission">
              Languages
            </label>
            <div class="cobalt-CheckablePillsGroup">
              {rentYourCar?.driver?.languages?.map((val) => (
                <div
                  class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                  data-form-checkmark-method="equipments"
                >
                  <label class="cobalt-CheckablePillField__LabelWrapper">
                    <input
                      id={`checkable-pill-${val.language_id}`}
                      className="cobalt-CheckablePillField__Input"
                      type="checkbox"
                      name="languages"
                        checked={selectDriverCase?.languages?.includes(val?.language_id)}
                      value={val.language_id}
                        onChange={(e) =>
                          handleChange(
                            e,
                            setSelectDriverCase,
                            selectDriverCase,
                            "caseDriver"
                          )
                        }
                    />
                    <span class="cobalt-CheckablePillField__Label">
                      {/* <span class="cobalt-Icon cobalt-Icon--car">
                       
                        <span class="cobalt-Icon cobalt-Icon--car">
                        <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
                      </span>
                      </span> */}
                      {val?.language_name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div class="cobalt-FormField">
            <label class="cobalt-FormField__Label" for="car_transmission">
              Tourist Guide
            </label>
            <div
              class="cobalt-CheckmarkField"
              data-form-checkmark-method="transmission"
            >
              <label class="cobalt-CheckmarkField__LabelWrapper">
                <input
                  class="cobalt-CheckmarkField__Input"
                  type="radio"
                  checked={selectDriverCase.touristGuide == "1"}
                    value="1"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDriverCase,
                        selectDriverCase,
                        "caseDriver",
                        "radio"
                      )
                    }

                    name="touristGuide"
                />
                <span class="cobalt-CheckmarkField__Checkmark"></span>
                <span class="cobalt-CheckmarkField__Label">Yes</span>
              </label>
            </div>
            <div
              class="cobalt-CheckmarkField"
              data-form-checkmark-method="transmission"
            >
              <label class="cobalt-CheckmarkField__LabelWrapper">
                <input
                  class="cobalt-CheckmarkField__Input"
                  type="radio"

                  checked={selectDriverCase.touristGuide == "0"}
                    value="0"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDriverCase,
                        selectDriverCase,
                        "caseDriver",
                        "radio"
                      )
                    }
                    name="touristGuide"
                />
                <span class="cobalt-CheckmarkField__Checkmark"></span>
                <span class="cobalt-CheckmarkField__Label">No</span>
              </label>
            </div>
          </div>

          
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Attract drivers to your listing and gain more bookings 💪
        </div>
      </div>
    </>
  );
};

export default DriverSkills;
