import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverLisenceNext = () => {
  const {  setSelectDriverCase,
    selectDriverCase } =
    useContext(DataContext);
//   const [selectData, setSelectData] = useState({
//     carBrand: "",
//     carModel: "",
//   });
//   console.log("selectDatanew", selectDataCaseOne);

//   useEffect(() => {
//     if (rentYourCar?.make?.length > 0 && rentYourCar?.model?.length > 0) {
//       setSelectData({
//         carBrand: rentYourCar.make[0].id,
//         carModel: rentYourCar.model[0].id,
//       });
//     }
//   }, [rentYourCar]);

  return (
    <>
     <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
      <div class="c-text-section-heading c-mb-xs">List my car</div>
      <div class="c-text-title-md c-mb-lg">Driver's license details</div>
      <div class="c-mb-lg">
        <div class="cobalt-FormField" data-form-field-method="address_line1">
          <label class="cobalt-FormField__Label" for="car_address_line1">
          License number

          </label>
          <div class="cobalt-TextField">
            <input
              class="cobalt-TextField__Input"
              type="text"
              name="licenseNumber"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.licenseNumber}
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Date of issue
          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="date"
              name="dateIssue"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.dateIssue}
            />
          </div>
        </div>
          <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Expiration date

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="date"
              name="expiryDate"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.expiryDate}
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="postal_code">
          <label class="cobalt-FormField__Label" for="car_postal_code">
          Date of birth


          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="date"
              name="dateBirth"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.dateBirth}
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="city">
          <label class="cobalt-FormField__Label" for="car_city">
          Blood type

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="text"
              name="bloodType"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.bloodType}
            />
          </div>
        </div>
       
      </div>
      <div class="cobalt-Note cobalt-Note--fullWidth">
        In order for you to receive payments, we use your billing address,
        phone number and email. You can edit them in your account details.
      </div>
    </div>
    </>
  );
};

export default DriverLisenceNext;
