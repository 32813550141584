import React, { useContext, useState, useEffect } from "react";
import StepModal from "./StepModal";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import Registration from "./Registration";
import Milage from "./Milage";
import EnergyTransmition from "./EnergyTransmition";
import StepsDoor from "./StepsDoor";
import FeaturesOption from "./FeaturesOption";
import StepPro from "./StepPro";
import BillingAddress from "./BillingAddress";
import Contact from "./Contact";
import MaintainCertification from "./MaintainCertification";
import StandardParking from "./StandardParking";
import PriceIntroduction from "./PriceIntroduction";
import PricingEducation from "./PricingEducation";
import "../../../search.css";
import {
  updateStep0,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
} from "../../../../api";
import { DataContext } from "../../../../ContextApi";
import ImageUplaod from "../ImageUplaod";
import RegionAddress from "./RegionAddress";
import Reduction from "./Reduction";
import Cancelation from "./Cancelation";
import CancelationType from "./CancelationType";
import CancelationTypeInsurance from "./CancelatioTypeInsurance";
import VehicleDemension from "./VehicleDemension";
import InsuranceDates from "./InsuranceDates";
import RentalDuration from "./RentalDuration";
const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    selectDataCaseOne,
    selectDataCaseTwo,
    selectDataCaseThree,
    selectDataCaseFour,selectStateWithoutDriverCase,setSelectStateWithoutDriverCase,selectStateDriverCase,setSelectStateDriverCase,
    selectDataCaseFiveLocation,
    setInputValue,imageArray,
    setSelectDataCaseFour,
    rentYourCar,
    openDialog,
    imageURLs,
    latLngRegion,
  } = useContext(DataContext);
  const [customWidth, setCustomWidth] = useState("0%");
  const [loading, setLoading] = useState(false);

  console.log(
    "selectDataCaseOne.carModel",
    rentYourCar?.vehcile_type?.find(
      (val) => val?.vehicle_type_id === selectDataCaseOne?.vehicleType
    )
  );
  // if(rentYourCar?.vehcile_type.find((val)=>val.vehicle_type_id=== selectDataCaseOne.carModel)?.vehicle_type=="Commercial")

  const getBasePath = (path) => {
    const match = path.match(/^(\/car_wizards\/\d+)/);
    return match ? match[1] : "";
  };
  const match = useMatch("/car_wizards/:id/*");
  const step = match.params["*"];
  const id = match ? match.params.id : null;
  console.log("idddfinal", id);
  // Check if the step is 'step_billing'
  const isStepBilling = step === "step_billing";
  console.log("customWidth", isStepBilling);
  const calculateWidth = (path) => {
    const basePath = getBasePath(path);
    switch (path) {
      case `${basePath}/step_modal`:
        return "7%";
      case `${basePath}/region_address`:
        return "10%";
      case `${basePath}/step_registration`:
        return "14%";
      case `${basePath}/step_milage`:
        return "21%";
      case `${basePath}/rental_duration`:
        return "28%";
      // case `${basePath}/step_energy`:
      //   return "28%";
      case `${basePath}/step_door`:
        return "35%";
      case `${basePath}/vehicle_dimension`:
        return "36%";
      case `${basePath}/insurance_date`:
        return "37%";
      case `${basePath}/reduction`:
        return "37%";
      case `${basePath}/cancelation`:
        return "40%";
      case `${basePath}/cancelation_type`:
        return "45%";
      case `${basePath}/cancelation_type_insurance`:
        return "48%";
      case `${basePath}/step_features`:
        return "50%";
      // case `${basePath}/step_pro`:
      //   return "49%";
      case `${basePath}/step_billing`:
        return "56%";
      case `${basePath}/step_contact`:
        return "63%";
      // case `${basePath}/step_maintain`:
      //   return "70%";
      case `${basePath}/step_standard`:
        return "77%";
      case `${basePath}/step_price`:
        return "84%";
      case `${basePath}/step_price_education`:
        return "91%";
      case `${basePath}/image_upload`:
        return "96%";
      default:
        return "0%";
    }
  };

  useEffect(() => {
    const path = location.pathname;
    setCustomWidth(calculateWidth(path));
  }, [location.pathname]);

  const renderFooterButtons = () => {
    const path = location.pathname;
    const basePath = getBasePath(path);

    if (
      path === `${basePath}/rental_duration` ||
      path === `${basePath}/step_contact` ||
      path === `${basePath}/image_upload`
    ) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                onClick={handleNextClick}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                onClick={handleNextClick}
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
        </>
      );
    } else if (path === `${basePath}/step_modal`) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div class="c-flex c-justify-end">
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                onClick={handleNextClick}
              />
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                onClick={handleNextClick}
              />
            </div>
          </div>
        </>
      );
    } else if (
      path === `${basePath}/step_registration` ||
      path === `${basePath}/region_address` ||
      path === `${basePath}/step_milage` ||
      path === `${basePath}/step_door` ||
      path === `${basePath}/vehicle_dimension` ||
      path === `${basePath}/insurance_date` ||
      path === `${basePath}/cancelation` ||
      path === `${basePath}/cancelation_type` ||
      path === `${basePath}/cancelation_type_insurance` ||
      path === `${basePath}/reduction` ||
      path === `${basePath}/step_features` ||
      path === `${basePath}/step_billing` ||
      path === `${basePath}/rental_duration` ||
      path === `${basePath}/step_contact` ||
      path === `${basePath}/step_standard` ||
      path === `${basePath}/step_price` ||
      path === `${basePath}/step_price_education`
    ) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div
              class={
                isStepBilling
                  ? "c-flex c-justify-between"
                  : "c-flex c-justify-end"
              }
            >
              {isStepBilling && (
                <div>
                  {" "}
                  <a
                    class="cob-Button cob-Button__ghost c-mr-sm"
                    onClick={handleSkipClick}
                  >
                    {/* <span class="cob-Button__Icon cob-Button__Icon--left">
                    <span class="cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </span>
                  </span> */}
                    <span>Skip</span>
                  </a>
                </div>
              )}
              <div>
                <a
                  class="cob-Button cob-Button__ghost c-mr-sm"
                  onClick={handlePreviousClick}
                >
                  <span class="cob-Button__Icon cob-Button__Icon--left">
                    <span class="cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  <span>Previous</span>
                </a>
                <input
                  type="submit"
                  name="commit"
                  value="Next"
                  onClick={handleNextClick}
                  class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                />
              </div>
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                value="Next"
                onClick={handleNextClick}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
        </>
      );
    }
  };
  const handleNextClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);

    switch (path) {
      case `${basePath}/step_modal`:
        setCustomWidth("7%");

        let payload = {
          makeId: selectDataCaseOne?.carBrand,
          modelId: selectDataCaseOne?.carModel,
          vehicleType: selectDataCaseOne?.vehicleType,
          fuelType: selectDataCaseOne?.fuelType,
        };
        console.log("updateStep0", payload);
        setLoading(true);
        updateStep0(payload, id, navigate, basePath, openDialog, setLoading);

        break;
      case `${basePath}/region_address`:
        setCustomWidth("14%");

        navigate(`${basePath}/step_registration`);
        break;
      case `${basePath}/step_registration`:
        if (
          rentYourCar?.vehcile_type.find(
            (val) => val.vehicle_type_id === selectDataCaseOne.vehicleType
          )?.vehicle_type == "Commercial"
        ) {
          navigate(`${basePath}/vehicle_dimension`);
        } else if (
          rentYourCar?.vehcile_type.find(
            (val) => val.vehicle_type_id === selectDataCaseOne.vehicleType
          )?.vehicle_type_id === 12
        ) {
          navigate(`${basePath}/insurance_date`);
        } else {
          navigate(`${basePath}/step_door`);
        }
        break;
      case `${basePath}/step_milage`:
      
        // setLoading(true);
        //  updateStep1(payloads, id, navigate, basePath, openDialog, setLoading);
        navigate(`${basePath}/rental_duration`);

        break;
      // case `${basePath}/step_energy`:
      //   navigate(`${basePath}/step_door`);
      //   break;
      case `${basePath}/step_door`:
        navigate(`${basePath}/insurance_date`);
        break;
      case `${basePath}/rental_duration`:
        let withDriver = rentYourCar?.state?.filter(region => selectStateDriverCase.states.includes(region.id))
        .map(region => {
            return {
                ...region,
                price: selectStateDriverCase?.[region.short_name]
            };
        });
        let withOutDriver = rentYourCar?.state?.filter(region => selectStateWithoutDriverCase.states.includes(region.id))
        .map(region => {
            return {
                ...region,
                price: selectStateWithoutDriverCase?.[region.short_name]
            };
        });
        let payloads = {
         
          driverStates:withDriver,
          withoutDriverStates:withOutDriver,
          ...selectDataCaseThree,...selectDataCaseTwo,...selectDataCaseFour
        };
        console.log("payloads", payloads);

          setLoading(true);
         updateStep2(payloads, id, navigate, basePath, openDialog, setLoading);
       navigate(`${basePath}/reduction`);
        break;
      case `${basePath}/vehicle_dimension`:
        navigate(`${basePath}/insurance_date`);
        break;
      case `${basePath}/insurance_date`:
        navigate(`${basePath}/cancelation_type_insurance`);
        break;
      case `${basePath}/reduction`:
        navigate(`${basePath}/step_features`);
        break;
      case `${basePath}/cancelation`:
        navigate(`${basePath}/cancelation_type`);
        break;
      case `${basePath}/cancelation_type`:
        navigate(`${basePath}/step_milage`);
        break;
      case `${basePath}/cancelation_type_insurance`:
        navigate(`${basePath}/step_contact`);
        break;
      case `${basePath}/step_features`:
        navigate(`${basePath}/step_billing`);
        break;
      // case `${basePath}/step_features`:
      //   let payloadss = { ...selectDataCaseThree };
      //   setLoading(true)
      //   updateStep2(payloadss,id,navigate,basePath,openDialog, setLoading);
      //   break;
      case `${basePath}/step_billing`:
        navigate(`${basePath}/image_upload`);
        break;
      // case `${basePath}/step_contact`:
      //   navigate(`${basePath}/step_maintain`);
      //   break;
      case `${basePath}/step_contact`:
        let payloadsss = {
          lat:selectDataCaseFiveLocation?.lat,
          lng:selectDataCaseFiveLocation?.lng,
          location:selectDataCaseFiveLocation?.location,
          ...selectDataCaseTwo,...selectDataCaseThree,...selectDataCaseFour
        };
        console.log("payloadsss",payloadsss)
        // delete payloadsss.country_code;
         setLoading(true)
          updateStep1(payloadsss,id,navigate,basePath,openDialog, setLoading);
        // setInputValue("")

         navigate(`${basePath}/cancelation`);
        break;
      // case `${basePath}/step_standard`:

      //   navigate(`${basePath}/image_upload`);
      //   break;
      // case `${basePath}/step_price`:
      //   navigate(`${basePath}/step_price_education`);
      //   break;
      //   case `${basePath}/step_price_education`:
      //   navigate(`${basePath}/image_upload`);
      //   break;
      case `${basePath}/image_upload`:
        if (imageURLs.length < 1) {
          openDialog("Minimum five Image Upload");
        } else {
          let payloads = {
         
       
            ...selectDataCaseThree,...selectDataCaseTwo,...selectDataCaseFour,images:imageURLs
          };
          console.log("payloads", payloads);
  
            setLoading(true);
           updateStep3(payloads, id, navigate, basePath, openDialog, setLoading);
          setLoading(true);
          // updateStep4(
          //   payloadsssd,
          //   id,
          //   navigate,
          //   basePath,
          //   openDialog,
          //   setLoading
          // );
        }

        break;
      default:
        break;
    }
  };
  const handleSkipClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);

    switch (path) {
      case `${basePath}/step_billing`:
        navigate(`${basePath}/image_upload`);
        setSelectDataCaseFour(() => ({
          ...selectDataCaseFour,
          address: "",
          address2: "",

          zip_code: "",
          city: "",
          country: "",

          state: "",
        }));
        break;

      default:
        break;
    }
  };
  const handlePreviousClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);
    const billing = localStorage.getItem("is_billing_address");

    switch (path) {
      case `${basePath}/region_address`:
        navigate(`${basePath}/step_modal`);
        break;
      case `${basePath}/step_registration`:
        navigate(`${basePath}/region_address`);
        break;
      case `${basePath}/step_milage`:
        navigate(`${basePath}/cancelation_type`);
        break;
      case `${basePath}/rental_duration`:
        navigate(`${basePath}/step_milage`);
        break;
      // case `${basePath}/step_energy`:
      //   navigate(`${basePath}/step_milage`);
      //   break;
      case `${basePath}/step_door`:
        navigate(`${basePath}/step_registration`);
        break;
      case `${basePath}/vehicle_dimension`:
        if (
          rentYourCar?.vehcile_type.find(
            (val) => val.vehicle_type_id === selectDataCaseOne.vehicleType
          )?.is_commercial === 1
        ) {
          navigate(`${basePath}/step_registration`);
        } else {
          navigate(`${basePath}/step_door`);
        }
        break;

      case `${basePath}/reduction`:
        navigate(`${basePath}/rental_duration`);
        break;
      case `${basePath}/cancelation`:
        navigate(`${basePath}/step_contact`);
        break;
      case `${basePath}/cancelation_type`:
        navigate(`${basePath}/cancelation`);
        break;

      case `${basePath}/cancelation_type_insurance`:
        navigate(`${basePath}/insurance_date`);
        break;
      case `${basePath}/insurance_date`:
        if (
          rentYourCar?.vehcile_type.find(
            (val) => val.vehicle_type_id === selectDataCaseOne.vehicleType
          )?.vehicle_type_id === 12
        ) {
          navigate(`${basePath}/step_registration`);
        } else if (
          rentYourCar?.vehcile_type.find(
            (val) => val.vehicle_type_id === selectDataCaseOne.vehicleType
          )?.is_commercial === 1
        ) {
          navigate(`${basePath}/vehicle_dimension`);
        } else {
          navigate(`${basePath}/step_door`);
        }
        break;
      case `${basePath}/step_features`:
        navigate(`${basePath}/reduction`);
        break;
      // case `${basePath}/step_pro`:
      //   navigate(`${basePath}/step_features`);
      //   break;

      case `${basePath}/step_billing`:
        navigate(`${basePath}/step_features`);
        break;

      case `${basePath}/step_contact`:
        if (billing == "1") {
          navigate(`${basePath}/cancelation_type_insurance`);
        } else {
          navigate(`${basePath}/cancelation_type_insurance`);
        }
        break;
      // case `${basePath}/step_maintain`:
      //   navigate(`${basePath}/step_contact`);
      //   break;
      // case `${basePath}/step_standard`:
      //   navigate(`${basePath}/step_contact`);
      //   break;
      // case `${basePath}/step_price`:
      //   navigate(`${basePath}/step_standard`);
      //   break;
      // case `${basePath}/step_price_education`:
      //   navigate(`${basePath}/step_price`);
      //   break;
      case `${basePath}/image_upload`:
        navigate(`${basePath}/step_billing`);
        break;
      default:
        break;
    }
  };
  return (
    <div class="full_width_content default_background">
      <div class="container">
        <div class="car_wizard">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 no-outer-gutter-xs">
              <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                <form
                  class="new_car"
                  id="new_car"
                  action="/car_wizards/1509611/steps/model"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <div class="cobalt-Card__Section cobalt-Card__Section--tight c-rounded-t-xl">
                    <div class="car_wizard__progression_bar">
                      <div
                        class="car_wizard__progression_bar_inner"
                        style={{ width: customWidth }}
                      ></div>
                    </div>
                  </div>
                  <Routes>
                    <Route
                      path="step_modal"
                      element={
                        <div>
                          {" "}
                          <StepModal />
                        </div>
                      }
                    />
                    <Route
                      path="region_address"
                      element={
                        <div>
                          {" "}
                          <RegionAddress />
                        </div>
                      }
                    />
                    <Route
                      path="step_registration"
                      element={
                        <div>
                          {" "}
                          <Registration />
                        </div>
                      }
                    />
                    <Route
                      path="step_milage"
                      element={
                        <div>
                          {" "}
                          <Milage />
                        </div>
                      }
                    />
                    <Route
                      path="rental_duration"
                      element={
                        <div>
                          {" "}
                          <RentalDuration />
                        </div>
                      }
                    />
                    <Route
                      path="step_energy"
                      element={
                        <div>
                          {" "}
                          <EnergyTransmition />
                        </div>
                      }
                    />
                    <Route
                      path="step_door"
                      element={
                        <div>
                          {" "}
                          <StepsDoor />
                        </div>
                      }
                    />
                    <Route
                      path="vehicle_dimension"
                      element={
                        <div>
                          {" "}
                          <VehicleDemension />
                        </div>
                      }
                    />
                    <Route
                      path="vehicle_dimension"
                      element={
                        <div>
                          {" "}
                          <VehicleDemension />
                        </div>
                      }
                    />
                    <Route
                      path="insurance_date"
                      element={
                        <div>
                          {" "}
                          <InsuranceDates />
                        </div>
                      }
                    />
                    <Route
                      path="cancelation"
                      element={
                        <div>
                          {" "}
                          <Cancelation />
                        </div>
                      }
                    />
                    <Route
                      path="cancelation_type"
                      element={
                        <div>
                          {" "}
                          <CancelationType />
                        </div>
                      }
                    />
                    <Route
                      path="cancelation_type_insurance"
                      element={
                        <div>
                          {" "}
                          <CancelationTypeInsurance />
                        </div>
                      }
                    />
                    <Route
                      path="step_features"
                      element={
                        <div>
                          {" "}
                          <FeaturesOption />
                        </div>
                      }
                    />

                    <Route
                      path="step_pro"
                      element={
                        <div>
                          {" "}
                          <StepPro />
                        </div>
                      }
                    />

                    <Route
                      path="reduction"
                      element={
                        <div>
                          {" "}
                          <Reduction />
                        </div>
                      }
                    />
                    <Route
                      path="step_billing"
                      element={
                        <div>
                          {" "}
                          <BillingAddress />
                        </div>
                      }
                    />
                    <Route
                      path="step_contact"
                      element={
                        <div>
                          {" "}
                          <Contact />
                        </div>
                      }
                    />
                    <Route
                      path="step_maintain"
                      element={
                        <div>
                          {" "}
                          <MaintainCertification />
                        </div>
                      }
                    />
                    <Route
                      path="step_standard"
                      element={
                        <div>
                          {" "}
                          <StandardParking />
                        </div>
                      }
                    />
                    <Route
                      path="step_price"
                      element={
                        <div>
                          {" "}
                          <PriceIntroduction />
                        </div>
                      }
                    />
                    <Route
                      path="step_price_education"
                      element={
                        <div>
                          {" "}
                          <PricingEducation />
                        </div>
                      }
                    />
                    <Route
                      path="image_upload"
                      element={
                        <div>
                          {" "}
                          <ImageUplaod />
                        </div>
                      }
                    />
                  </Routes>
                  {renderFooterButtons()}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
