import React, { useContext, useState, useRef } from "react";
import "./driver.css";
import { DataContext } from "../ContextApi";
import { addCompany, registerDriver } from "../api";
import { useNavigate } from "react-router-dom";
import image1 from "../image/png/main_img1.png";
import image2 from "../image/png/main_img2.png";
import image3 from "../image/png/main_img3.png";
import image4 from "../image/png/main_img4.png";
import image5 from "../image/png/main_img5.png";
import image6 from "../image/png/main_img6.png";
import quotes from "../image/png/quotes.svg";
import ReusableFeedback from "./ReusableFeedback";
import ReusableFaq from "./ReusableFaq";
const inputFields = [
  // { name: "company_name", label: "MY COMPANY", type: "text", required: true },
  { name: "first_name", label: "FIRST NAME", type: "text", required: true },
  { name: "last_name", label: "LAST NAME", type: "text", required: true },

  {
    name: "user_email",
    label: "EMAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
  { name: "user_phone", label: "PHONE", type: "text", required: true },
  {
    name: "user_password",
    label: "PASSWORD",
    type: "password",
    required: true,
  },

  {
    name: "driving_experience",
    label: "EXPERIENCE",
    type: "number",
    required: true,
  },

  // { name: "company_country", label: "COUNTRY", type: "text", required: true },
  // {
  //   name: "company_email",
  //   label: "E-MAIL",
  //   type: "email",
  //   required: true,
  //   placeholder: "your@email.com",
  // },
];

const BusinessSpace = () => {
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    user_phone: "",
    user_email: "",
    user_password: "",
    driving_experience: null,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { openDialog } = useContext(DataContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = { ...formValues };
    registerDriver(payload, openDialog, navigate);
  };
  const scrollRef = useRef(null);
  const scrollToRef = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <body style={{ height: "auto" }} class="v1_body">
      <div class="js_site_content site_content">
        <div class="search-hero-container">
          <div class="container">
            <div class="c-flex-">
              <div id="offersandbonuses" class="v1__section-4">
                <div class="v1__container-3">
                  <div style={{display:"flex",flexDirection:"column"}} class="hostcom_offers_grid">
                    <div class="div-block-235">
      <header class="v1__section-2">
        <div class="v1__container-3"><img
            src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a17c91f28b360a5bc09045_dwuguest1tiny.jpeg"
           alt="" class="v1_header_partial_img dwu"/>
          <div class="v1_half_black_box_div">
            <div class="v1__content_wrap padding--none">
              <div class="v1_header_partial_black_box">               
                  <h2 class="v1_heading_26-2 margin--none"><strong>
                    Drive Overlo cars. <span class="type-color--black">Earn with
                    Overlo.</span>
                  </strong></h2>
                <div class="dwu_hero-description">Need a car to drive for Uber and Uber Eats? Overlo offers
                  pre-approved cars, <span class="type--semibold-black">ready to rent by the hour, day, or week.</span>
                  Seamlessly connect with Overlo and start earning today.</div>
                <a                            onClick={scrollToRef}
 class="v1_arrow_button_regular_wrapper-copy" style={{width:"210px"}}>
                  <div class="v1_arrow_button_in space-between">
                    <div>Explore Offers</div>
                    <div class="v1_arrow_button_arrow regular w-embed"><svg width="auto" height="auto"
                        viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.1402 24.007L14.8516 22.7183L22.6421 14.9083L14.8516 7.11775L16.1402 5.80957L25.2389 14.9083L16.1402 24.007Z"
                          fill="CurrentColor"></path>
                        <path d="M23.9479 13.9912H7.37109V15.8266H23.9479V13.9912Z" fill="CurrentColor"></path>
                        <path
                          d="M15.9835 30.6271C12.9714 30.6271 10.0269 29.7339 7.52239 28.0605C5.0179 26.387 3.06589 24.0085 1.9132 21.2257C0.760505 18.4428 0.458908 15.3807 1.04655 12.4264C1.63418 9.47216 3.08466 6.75851 5.21455 4.62862C7.34445 2.49872 10.0581 1.04824 13.0124 0.460608C15.9666 -0.127029 19.0288 0.174567 21.8116 1.32726C24.5944 2.47995 26.973 4.43196 28.6464 6.93646C30.3199 9.44095 31.2131 12.3854 31.2131 15.3976C31.2079 19.4351 29.6017 23.3058 26.7467 26.1608C23.8917 29.0158 20.021 30.622 15.9835 30.6271ZM15.9835 2.00334C13.3344 2.00334 10.7447 2.78889 8.54206 4.26067C6.3394 5.73245 4.62262 7.82434 3.60885 10.2718C2.59507 12.7193 2.32982 15.4124 2.84664 18.0106C3.36346 20.6089 4.63913 22.9955 6.51235 24.8687C8.38557 26.7419 10.7722 28.0176 13.3704 28.5344C15.9686 29.0512 18.6618 28.786 21.1092 27.7722C23.5567 26.7584 25.6486 25.0417 27.1204 22.839C28.5922 20.6363 29.3777 18.0467 29.3777 15.3976C29.3726 11.8468 27.9597 8.4429 25.4489 5.93212C22.9382 3.42133 19.5343 2.0085 15.9835 2.00334Z"
                          fill="CurrentColor"></path>
                      </svg></div>
                  </div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </header>      
    </div>
    <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
                    <div
                      id="w-node-_8df7cebf-65d6-c7ca-eba4-a08a71f8662b-e419be09"
                      class="hostcom_offers_grid_div2"
                    >
                      <div class="hostcom_offers_grid_div2_content">
                        <img
                          src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a22_Car%20Illustration.png"
                          loading="lazy"
                          width="231"
                          alt=""
                          class="hostcom_offers_grid_div2_image"
                        />
                        <div class="general_content_spacing _0-50">
                          <h2 class="v1_heading_26-2 margin--none">
                            <strong>Partners, Deals &amp; Discounts</strong>
                          </h2>
                          <div class="v1_paragraph_regular-2 margin--none">
                            As a Overlo host, you get access to exclusive deals
                            and discounts with our entire network of partners.
                            <br></br>
                          </div>
                          <a class="v1_arrow_button_regular_wrapper-copy">
                            <div class="v1_arrow_button_in space-between">
                              <div>Explore Offers</div>
                              <div class="v1_arrow_button_arrow regular w-embed">
                                <svg
                                  width="auto"
                                  height="auto"
                                  viewBox="0 0 32 31"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.1402 24.007L14.8516 22.7183L22.6421 14.9083L14.8516 7.11775L16.1402 5.80957L25.2389 14.9083L16.1402 24.007Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M23.9479 13.9912H7.37109V15.8266H23.9479V13.9912Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M15.9835 30.6271C12.9714 30.6271 10.0269 29.7339 7.52239 28.0605C5.0179 26.387 3.06589 24.0085 1.9132 21.2257C0.760505 18.4428 0.458908 15.3807 1.04655 12.4264C1.63418 9.47216 3.08466 6.75851 5.21455 4.62862C7.34445 2.49872 10.0581 1.04824 13.0124 0.460608C15.9666 -0.127029 19.0288 0.174567 21.8116 1.32726C24.5944 2.47995 26.973 4.43196 28.6464 6.93646C30.3199 9.44095 31.2131 12.3854 31.2131 15.3976C31.2079 19.4351 29.6017 23.3058 26.7467 26.1608C23.8917 29.0158 20.021 30.622 15.9835 30.6271ZM15.9835 2.00334C13.3344 2.00334 10.7447 2.78889 8.54206 4.26067C6.3394 5.73245 4.62262 7.82434 3.60885 10.2718C2.59507 12.7193 2.32982 15.4124 2.84664 18.0106C3.36346 20.6089 4.63913 22.9955 6.51235 24.8687C8.38557 26.7419 10.7722 28.0176 13.3704 28.5344C15.9686 29.0512 18.6618 28.786 21.1092 27.7722C23.5567 26.7584 25.6486 25.0417 27.1204 22.839C28.5922 20.6363 29.3777 18.0467 29.3777 15.3976C29.3726 11.8468 27.9597 8.4429 25.4489 5.93212C22.9382 3.42133 19.5343 2.0085 15.9835 2.00334Z"
                                    fill="CurrentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_8df7cebf-65d6-c7ca-eba4-a08a71f8663a-e419be09"
                      class="hostcom_offers_grid_div2"
                    >
                      <div class="hostcom_offers_grid_div2_content refer-a-friend">
                        <div class="general_content_spacing _0-50">
                          <h2 class="v1_heading_26-2 margin--none">
                            <strong>Refer a friend, earn money</strong>
                          </h2>
                          <div class="v1_paragraph_regular-2 margin--none">
                            Earn even more when you refer a friend to host on
                            Getaround.<br></br>
                            <a class="link-type--underline-4">
                              <strong>Share your referral link</strong>
                            </a>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    </div>
</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="owner_homepage_usps">
            <div class="container">
              <ul class="owner_usps">
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">88,000 +</div>
                  <p class="c-text-body-md c-text-subdued">
                    Professional users
                  </p>
                </li>
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">200,000 +</div>
                  <p class="c-text-body-md c-text-subdued">Business trips</p>
                </li>
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">1,400 +</div>
                  <p class="c-text-body-md c-text-subdued">
                    Registered companies
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section class="v1__section-2">
          <div class="v1__container-3">
            <div class="v1_margin-bottom _2">
              <div class="v1_heading_42 centered">
                Become a driver
              </div>
            </div>
            <div class="hostcom_menu_grid">
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image1}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">Save time</div>
                <p>
                  A quick and digital reservation. An inventory carried out in 5
                  minutes.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image2}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  100% guaranteed journeys
                </div>
                <p>
                  Each rental is fully insured by Axa, and covered by 24/7
                  roadside assistance.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image3}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Customer service available 7 days a week
                </div>
                <p>
                  So that you are never left by the wayside, our team is here to
                  listen to you.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image4}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Overlo chosen car
                </div>
                <p>
                  So that you are never left by the wayside, our team is here to
                  listen to you.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image5}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">100% free</div>
                <p>
                  Opening and managing your account without additional costs and
                  without commitment
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image6}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Popular with businesses of all sizes
                </div>
                <p>
                  Whether you are a company, a self-employed person or an
                  association
                </p>
              </a>
            </div>
          </div>
        </section>

        <section class="v1__section-2">
          <div class="v1__container-3">
            <div class="v1_margin-bottom _2">
              <div class="v1_heading_42 centered">
                Features dedicated to businesses
              </div>
            </div>
            <div class="v1__content_wrap flex--h-double-column">
              <div class="v1__content">
                <img
                  src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a1b_ga-illustration-8.svg"
                  loading="lazy"
                  sizes="(max-width: 479px) 65vw, (max-width: 767px) 355px, (max-width: 991px) 38vw, 355px"
                  alt=""
                  class="dwu_steps-img"
                />
              </div>
              <div
                data-w-id="6bbbb8e9-7996-c4ed-fdae-dd093a3dc77f"
                class="v1__content"
              >
                <div class="v1__timeline_grid _4-itens">
                  <h3 id="sds_tmh_1" class="v1__timeline_heading _1 home">
                    Simplified accounting
                  </h3>
                  <div id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc783-f615a809">
                    <div
                      id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc784-f615a809"
                      class="v1__timeline_text _1 home"
                    >
                      Your payment receipts in the name of the company, and the
                      possibility of issuing your VAT invoices
                    </div>
                  </div>
                  <h3 class="v1__timeline_heading _2 home">
                    Centralized payment
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc790-f615a809"
                    class="v1__timeline_text _2 home"
                  >
                    A single payment method for travel for all your team
                    members.
                  </div>
                  <h3
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc792-f615a809"
                    class="v1__timeline_heading _3 home"
                  >
                    Managing your team
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc794-f615a809"
                    class="v1__timeline_text _3 home"
                  >
                    Add and remove your team members as you wish and without
                    limits
                  </div>
                  <h3
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc79b-f615a809"
                    class="v1__timeline_heading _4 home"
                  >
                    Or Connect
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc79d-f615a809"
                    class="v1__timeline_text _4 home"
                  >
                    Once your Getaround rental starts, the car information will
                    appear in the Uber Driver app. Go online and start earning!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="v1__section-4">
          <div class="v1__container-3">
            <div class="v1_heading_42 centered">You are in good company</div>
            <div class="v1_margin-bottom _2 text-center">
              Find out what our customers say about us
            </div>
            <div class="v1_spacing-3"></div>
            <div
              data-delay="4000"
              data-animation="slide"
              class="v1_testimonials_slide__wrap w-slider"
            >
              <div
                class="v1_testimonials_slide__mask w-slider-mask"
                id="w-slider-mask-0"
              >
                <div
                  class="v1_testimonials_slide__card_wrap w-slide"
                  aria-label="1 of 6"
                  role="group"
                >
                  <div class="v1_testimonials_slide__card_out">
                    <div class="v1_testimonials_slide__card">
                      <img
                        src={quotes}
                        loading="lazy"
                        width="8.5"
                        alt=""
                        class="v1_testimonials_slide__card_quote"
                      />

                      <div class="tm_card_body_text">
                        Our filming is all over France. With my cameraman we
                        take the train and pick up the car at the station at any
                        time.<br></br>
                      </div>
                      <div class="tm_card_body_text">
                        ‍
                        <a target="_blank">
                          <span>
                            <strong class="bold-text-14">
                              Hélène M. - Journalist, Bordeaux
                            </strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v1_testimonials_slide__card_wrap w-slide"
                  aria-label="2 of 6"
                  role="group"
                  style={{
                    transition:
                      "all, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
                    transform: "translateX(0px)",
                    opacity: 1,
                  }}
                >
                  <div class="v1_testimonials_slide__card_out">
                    <div class="v1_testimonials_slide__card">
                      <img
                        src={quotes}
                        loading="lazy"
                        width="8.5"
                        alt=""
                        class="v1_testimonials_slide__card_quote"
                      />
                      <div class="tm_card_body_text">
                        All our employees were able to rent a car near their
                        home to come to our seminar in Île de France, it was
                        more practical for everyone.<br></br>
                      </div>
                      <div class="tm_card_body_text">
                        <a target="_blank">
                          <span>
                            <strong class="bold-text-13">
                              Laura R. - HR & Office Manager, Paris
                            </strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="helpcenter"
          class="v1__section-4"
          style={{ marginTop: "40px", marginBottom: "104px" }}
        >
          <div class="v1__container-3">
            <div
              data-w-id="8df7cebf-65d6-c7ca-eba4-a08a71f866e7"
              class="v1_ctaimgcard_wrap-3"
            >
              <div class="v1__content_wrap mobile-padding--none">
                <div class="v1_ctaimgcard_img_wrap-2">
                  <img
                    src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a24_AS_488832115.png"
                    loading="lazy"
                    sizes="(max-width: 767px) 94vw, (max-width: 991px) 77vw, (max-width: 1919px) 73vw, 856.796875px"
                    alt=""
                    class="v1_ctaimgcard_img"
                  />
                </div>
              </div>
              <div
                class="v1_ctaimgcard_card_wrap-2 padding--top"
                style={{
                  willChange: "transform",
                  transform:
                    "translate3d(0px, -0.75244%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
              >
                <div class="general_content_spacing _0-50">
                  <h2 class="v1_heading_32-10 margin--none">
                    <strong>Achieve your CSR objectives</strong>
                  </h2>
                  <div class="v1_paragraph_regular-2 margin--none">
                    Car sharing plays an important role in decarbonizing
                    transportation, including your business footprint.<br></br>
                    <ul class="CSR_objectives">
                      <li>
                        <h4>1 connected car shared on Getaround</h4>
                        <p>replaces 4 individual vehicles</p>
                      </li>
                      <li>
                        <h4>-40% annual CO2 emissions</h4>
                        <p>switching from a vehicle to car sharing</p>
                      </li>
                      <li>
                        <h4>52 million km saved each year</h4>
                        <p>thanks to car sharing on Getaround in France</p>
                      </li>
                      <li>
                        <h4>Ademe figures, car sharing study, 2019</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section style={{ display: "none" }} class="insurance_layer">
          <div class="container">
            <div class="wr">
              <div
                class="v1_heading_42 centered"
                style={{ color: "#fff", marginBottom: "50px", display: "none" }}
              >
                What is car sharing?
              </div>
              <div class="features features-trio features--left">
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/qpGqactlwkZTzkiY.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Individually shared vehicles
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing optimizes the use of cars by users: you share
                      the use of a vehicle but you use the vehicle alone when
                      you need it.<br></br>
                      Not to be confused with carpooling.
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/GVUIxrVGYoJQpdiR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Savings per month
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing reduces the costs associated with owning a
                      fleet of vehicles: insurance, maintenance, parking fees,
                      loan repayments. Focus your time solely on your area of
                      ​​activity.
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/gaAunYTFbSdLslMR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Protect the environment
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing means fewer vehicles on the road, therefore a
                      reduction in CO2 and greenhouse gas emissions. This allows
                      you to adopt a sustainable strategy to achieve your
                      objectives in this area. You can measure this impact using
                      the "ADEME Grid".
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/gaAunYTFbSdLslMR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Preparing for the disappearance of the automobile in the
                    city
                  </h3>
                  <div class="feature__description">
                    <p>
                      Cities are setting an example by doing everything they can
                      to make the car disappear from the urban landscape. Owning
                      a fleet of vehicles will become increasingly difficult for
                      your business. Car sharing prepares you for a new era,
                      where the car will be a shared good (like the train, the
                      plane).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={scrollRef} class="owner_homepage_pros__layer">
          <div class="container owner_homepage_pros__container">
            <h2 class="owner_homepage_pros__headline">
              You are already a professional or want to become one with Overlo?
            </h2>
            <div id="dashboard-cars-show">
              {/* <div className="js_site_content site_content">
                <div
                  id="form"
                  className="section--grid section--center dark"
                  style={{
                    paddingBottom: "60px",
                    paddingTop: "60px",
                    "--pdx-min-height": "0px",
                    "--wr-max": "1030px",
                  }}
                >
                  <div className="wr titleAlign--">
                    <div className="titleWrapper"></div>
                    <div
                      className="formWrapper"
                      style={{
                        borderRadius: "3px",
                        maxWidth: "600px",
                        margin: "0 auto",
                      }}
                    >
                      <div
                        className="fontfamily"
                        data-form-checkmark-method="is_pro"
                      >
                        <label
                          className="Company_info"
                          style={{ verticalAlign: "inherit" }}
                        >
                          Add Driver{" "}
                          <span style={{ verticalAlign: "inherit" }}>*</span>
                        </label>
                      </div>
                      <form
                        className="form"
                        style={{
                          "--cf-bg": "#fafafa !important",
                          "--cf-color": "#000",
                          "--cf-placeholder": "#bbb",
                          "--cf-borderColor": "rgba(126, 126, 126, 0.3)",
                        }}
                        onSubmit={handleSubmit}
                      >
                        {inputFields.map(
                          (field, index) =>
                            (open || index < 5) && (
                              <div className="formRow" key={field.name}>
                                <div
                                  className="cf-field"
                                  data-id={field.name.toUpperCase()}
                                  data-label={field.label}
                                  data-required={field.required}
                                  data-field={field.type}
                                >
                                  <label style={{ verticalAlign: "inherit" }}>
                                    {field.label}{" "}
                                    <span style={{ verticalAlign: "inherit" }}>
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    value={formValues[field.name]}
                                    placeholder={field.placeholder || ""}
                                    onChange={handleChange}
                                    required={field.required}
                                    disabled={field.name === "company_country"}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      
                        <div className="ctaWrapper">
                          <button
                            className="btn primary btn--large"
                            type="submit"
                          >
                            Create my Account
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}
              <div  style={{textAlign:"center",cursor:"pointer"}}  className="ctaWrapper">
                          <button
                          onClick={()=>navigate("/add_driver/1/driver_license")}
                            className="btn primary btn--large"
                            type="submit"
                          >
                            Become a Driver
                          </button>
                        </div>
            </div>
          </div>
         
        </section>
        <ReusableFeedback/>
        <ReusableFaq/>
      </div>
    </body>
  );
};

export default BusinessSpace;
