import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const InsuranceDates = () => {
  const { selectDataCaseThree, setSelectDataCaseThree } = useContext(DataContext);

  return (
    <>
    <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
      <div class="c-text-section-heading c-mb-xs">List my car</div>
      <div class="c-text-title-md c-mb-lg">Vehicle insurance</div>
      <div class="c-mb-lg">
        <div class="cobalt-FormField" data-form-field-method="address_line1">
          <label class="cobalt-FormField__Label" for="car_address_line1">
          Date          </label>
          <div class="cobalt-TextField">
            <input
              class="cobalt-TextField__Input"
              type="date"
              name="insurance_date"
             value={selectDataCaseThree?.insurance_date}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Date of issuance

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="date"
              name="issue_date"
             value={selectDataCaseThree?.issue_date}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
          <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Expiration date

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="date"
              name="expiry_date"
             value={selectDataCaseThree?.expiry_date}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
      
      
       
      </div>
      <div class="cobalt-Note cobalt-Note--fullWidth">
        In order for you to receive payments, we use your billing address,
        phone number and email. You can edit them in your account details.
      </div>
    </div>
  </>


);
};

export default InsuranceDates;
