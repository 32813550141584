import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverPricingNext = () => {
  const { setSelectDriverCase, selectDriverCase, selectDataCaseFour,rentYourCar } =
    useContext(DataContext);
  const [selectedExtraId, setSelectedExtraId] = useState("yes_region_driver");
  const [selectRadioId, setSelectedRadioId] = useState(
    selectDataCaseFour?.driver_option_id[0]
  );
  const [prices, setPrices] = useState({});

  const handlePriceChange = (e, stateId) => {
    const value = e.target.value;
    setPrices(prevPrices => ({
      ...prevPrices,
      [stateId]: value
    }));
  };

  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };
  console.log("selectedfour",  selectDriverCase);
  return (
    <>
  
     
         
            <div
              id="2"
              class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main"
            >
              <div class="c-text-section-heading c-mb-xs">List my car</div>
              <div class="c-text-title-md c-mb-lg">Pricing Conditions

</div>

              

              <div class="cobalt-FormField">
                <label class="cobalt-FormField__Label" for="car_transmission">
                Do you allow travel to other zones for an additional fee?  
                {" "}
                </label>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="yes_region_driver"
                      checked={selectedExtraId === "yes_region_driver"}
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">Yes</span>
                  </label>
                </div>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="no_region_driver" // Example value, replace with your actual value
                      checked={selectedExtraId === "no_region_driver"} // Adjust this check as per your logic
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">No</span>
                  </label>
                </div>
              </div>
              {selectedExtraId === "no_region_driver" ? (
                ""
              ) : selectedExtraId === "yes_region_driver" ? (
                <div
                  class="cobalt-FormField"
                  data-form-field-method="plate_number"
                >
                  <div class="cobalt-CheckablePillsGroup">
              {rentYourCar?.state?.map((val) => (
                <div style={{display:"flex"}}>
                <div
                  class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                  data-form-checkmark-method="equipments"
                >
                  <label class="cobalt-CheckablePillField__LabelWrapper">
                    <input
                      id={`checkable-pill-${val.id}`}
                      className="cobalt-CheckablePillField__Input"
                      type="checkbox"
                      name="states"
  checked={selectDriverCase?.states?.some((item) => item == val.id)} // Check if an object with the same id exists
                      value={val.id}
                        onChange={(e) =>
                          handleChange(
                            e,
                            setSelectDriverCase,
                            selectDriverCase,
                            "caseDriver"
                          )
                        }
                    />
                    <span class="cobalt-CheckablePillField__Label">
                      {/* <span class="cobalt-Icon cobalt-Icon--car">
                       
                        <span class="cobalt-Icon cobalt-Icon--car">
                        <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
                      </span>
                      </span> */}
                      {val?.name}
                    </span>
                  </label>
                </div>
               
              {selectDriverCase?.states.includes(val.id) && (
<>
                  {/* <label class="cobalt-FormField__Label" for="car_plate_number">
                    Extra Price for  {val.name}: zone{" "}
                  </label> */}
                  <div class="cobalt-TextField">
                  <div
              style={{
                display: "flex",
                fontSize: "25px",
                fontWeight: "800",
                gap: "10px",
              }}
              class="cobalt-TextField"
            >
              $
                    <input
                     style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  width: "20%",
                }}
                      required
                      class="cobalt-TextField__Input"
                      type="number"
                      name={val?.short_name}
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "e" || e.key === "+") {
                          e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Allow only positive numbers
                        if (value >= 0) {
                          handleChange(
                            e,
                            setSelectDriverCase,
                            selectDriverCase,
                            "caseDriver"
                          );
                        }
                      }}
                      value={selectDriverCase[val?.short_name] || ""}

                //     value={prices[val.id] || ''}
                //   onChange={(e) => handlePriceChange(e, val.id)}
                    />
                    </div>
                  </div>
                  </>
            )}
              </div>
              ))}

            </div>
           
                </div>
              ) : (
                ""
              )}
              <div class="cobalt-Note cobalt-Note--fullWidth">
                This information allows us to ensure the quality and safety of
                our fleet.
              </div>
            </div>
         
        
    </>
  );
};

export default DriverPricingNext;
