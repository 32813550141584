import locationPin from "../image/svg/location-pin.svg";
import plane from "../image/svg/plane.svg";
import calender from "../image/svg/calender.svg";
import handfree from "../image/svg/handfree.svg";
import pickupcar from "../image/svg/pickupcar.svg";
import commercialcar from "../image/svg/commercialcar.svg";
import familycar from "../image/svg/familycar.svg";
import suvcar from "../image/svg/suvcar.svg";
import cardRent from "../image/svg/card-rental.svg";
import Request from "../component/Pages/MenuPages/Request";
import Rentals from "../component/Pages/MenuPages/Rentals";
import Cars from "../component/Pages/MenuPages/Cars";
import Performance from "../component/Pages/MenuPages/Performance";
import Payments from "../component/Pages/MenuPages/Payments";
import Account from "../component/Pages/MenuPages/Accounts/Account";
import EditProfile from "../component/Pages/MenuPages/Accounts/EditProfile";
import Verify from "../component/Pages/MenuPages/Accounts/Verify";
import Notification from "../component/Pages/MenuPages/Accounts/Notification";
import PaymentMethod from "../component/Pages/MenuPages/Accounts/PaymentMethod";
import Referral from "../component/Pages/MenuPages/Accounts/Referral";
import AccountSetting from "../component/Pages/MenuPages/Accounts/AccountSetting";
import Role from "../component/Pages/MenuPages/Role";
import Accounts from "../component/Pages/MenuPages/Accounts";
import AddImageCar from "../component/Pages/MenuPages/AddImageCar";
import AddMoreImage from "../component/AddMoreImage";
import WrongRoute from "../component/WrongRoute";
import CompanyData from "../component/Pages/MenuPages/Company";
export const arrFour = [
  {
    id: 1,
    image: locationPin,
    text: "More Locations",
    detail: "Odiovi verra tincidunt tristique ullamcor",
  },

  {
    id: 2,
    image: plane,
    text: " Airport delivery",
    detail: "Odiovi verra tincidunt tristique ullamcor",
  },
  {
    id: 3,
    image: calender,
    text: "curbside delivery",
    detail: "Odiovi verra tincidunt tristique ullamcor",
  },
  {
    id: 4,
    image: handfree,
    text: "24/7 support",
    detail: "Odiovi verra tincidunt tristique ullamcor",
  },
];

export const arrFourFeatures = [
  {
    id: 1,
    image: pickupcar,
    text: "Pickup",
    route:"pickup"
  },

  {
    id: 2,
    image: commercialcar,
    text: "Commercial",
    route:"commercial"

  },
  {
    id: 3,
    image: familycar,
    text: "Family car",
    route:"family"

  },
  {
    id: 4,
    image: suvcar,
    text: "SUV",
    route:"suv"

  },
];
export const arrThreeRental = [
  {
    id: 1,
    image: cardRent,
    text: "car rental",
    detail:
      "Viverra tincidunt tristique ullamcor blandit dipiscing risus elemen",
  },

  {
    id: 2,
    image: cardRent,
    text: "car rental",
    detail:
      "Viverra tincidunt tristique ullamcor blandit dipiscing risus elemen",
  },
  {
    id: 3,
    image: cardRent,
    text: "car rental",
    detail:
      "Viverra tincidunt tristique ullamcor blandit dipiscing risus elemen",
  },
];

export const menuArrayRoute = [
  {
    id: 1,
    name: "Request",
    path: "request",
    component: (
      <div>
        <Request />
      </div>
    ),
  },
  {
    id: 2,
    name: "Rentals",
    path: "rentals",
    component: (
      <div>
        <Rentals />
      </div>
    ),
  },
  {
    id: 3,
    name: "Cars",
    path: "cars",
    component: (
      <div>
        <Cars />
      </div>
    ),
  },

  {
    id: 4,
    name: "Notifications",
    path: "notifications",
    component: (
      <div>
        <Performance />
      </div>
    ),
  },
  {
    id: 5,
    name: "Payments",
    path: "payments",
    component: (
      <div>
        <Payments />
      </div>
    ),
  },
  {
    id: 6,
    name: "Profile",
    path: "profile/*",
    component: (
      <div>
        <Account />
      </div>
    ),
  },
  {
    id: 7,
    name: "Roles",
    path: "role",
    component: (
      <div>
        <Role />
      </div>
    ),
  },
  {
    id: 8,
    name: "Accounts",
    path: "accounts",
    component: (
      <div>
        <Accounts />
      </div>
    ),
  },
  {
    id: 9,
    name: "Images",
    path: "cars/:id",
    component: (
      <div>
        <AddImageCar />
      </div>
    ),
  },
  {
    id: 10,
    name: "MoreImages",
    path: "cars/:id/photos",
    component: (
      <div>
        <AddMoreImage />
      </div>
    ),
  },
  {
    id: 11,
    name: "WrongRoute",
    path: "wrong_route",
    component: (
      <div>
        <WrongRoute />
      </div>
    ),
  },
  {
    id: 12,
    name: "Company",
    path: "company",
    component: (
      <div>
        <CompanyData />
      </div>
    ),
  },
];

export const menuArray = [
  {
    id: 1,
    name: "Request",
    path: "request",
  },
  {
    id: 2,
    name: "Rentals",
    path: "rentals",
  },
  {
    id: 3,
    name: "Cars",
    path: "cars",
  },
  {
    id: 4,
    name: "Notifications",
    path: "notifications",
  },
  {
    id: 5,
    name: "Payments",
    path: "payments",
  },
  {
    id: 6,
    name: "Profile",
    path: "profile/edit-profile",
  },
  {
    id: 7,
    name: "Role",
    path: "role",
  },
  {
    id: 8,
    name: "Accounts",
    path: "accounts",
  },
  {
    id: 9,
    name: "Image",
    path: "cars/:id",
  },
  {
    id: 10,
    name: "MoreImages",
    path: "cars/:id/photos",
  },
  {
    id: 11,
    name: "Company",
    path: "company",
  },
];

export const LeftBarArray = [
  {
    id: 1,
    text: "Edit your profile",
    path: "edit-profile",
    component: (
      <div>
        <EditProfile />
      </div>
    ),
  },
  {
    id: 2,
    text: " Verify your profile",
    path: "verify",
    component: (
      <div>
        <Verify />
      </div>
    ),
  },
  {
    id: 3,
    text: "Account settings",
    path: "account_settings",
    component: (
      <div>
        <AccountSetting />
      </div>
    ),
  },
  {
    id: 4,
    text: "Notifications",
    path: "notifications",
    component: (
      <div>
        <Notification />
      </div>
    ),
  },
  {
    id: 5,
    text: "Payment methods",
    path: "payment_methods",
    component: (
      <div>
        <PaymentMethod />
      </div>
    ),
  },
  {
    id: 6,
    text: "Get $20 credit",
    path: "referral",
    component: (
      <div>
        <Referral />
      </div>
    ),
  },
];
export const handleChange = (
  event,
  setSelectData,
  selectData,
  storageName,
  radio,val
) => {
  const { name, value, type, checked } = event.target;
console.log("ban,,,",selectData)
  let updatedData;

  if (type === "checkbox" && name === "features") {
    const featureId = parseInt(value);
    const updatedFeatures = checked
      ? [...selectData.features, featureId]
      : selectData.features.filter((id) => id !== featureId);

    updatedData = {
      ...selectData,
      features: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "types_insurance") {
    const featureId = parseInt(value);
    const updatedFeatures = checked
      ? [...selectData?.types_insurance, featureId]
      : selectData?.types_insurance.filter((id) => id !== featureId);

    updatedData = {
      ...selectData,
      types_insurance: updatedFeatures,
    };
  }
  else if(type === "checkbox" && name === "driver_option_id")
  {
    const featureId = parseInt(value);
    const updatedFeatures = checked
      ? [...selectData.driver_option_id, featureId]
      : selectData.driver_option_id.filter((id) => id !== featureId);

    updatedData = {
      ...selectData,
      driver_option_id: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "discount") {
    const featureId = value;
  
    // Ensure selectData.discount is an array or initialize it as an empty array
    const currentDiscount = selectData?.discount || [];
  
    const updatedFeatures = checked
      ? currentDiscount.includes(featureId) // Check for duplicates
        ? currentDiscount // If featureId is already in the array, return the array as is
        : [...currentDiscount, featureId] // If not, add the featureId to the array
      : currentDiscount.filter((id) => id !== featureId); // Remove the featureId if unchecked
  
    updatedData = {
      ...selectData,
      discount: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "promotion") {
    const featureId = value;
  
    // Ensure selectData.discount is an array or initialize it as an empty array
    const currentDiscount = selectData?.promotion || [];
  
    const updatedFeatures = checked
      ? currentDiscount.includes(featureId) // Check for duplicates
        ? currentDiscount // If featureId is already in the array, return the array as is
        : [...currentDiscount, featureId] // If not, add the featureId to the array
      : currentDiscount.filter((id) => id !== featureId); // Remove the featureId if unchecked
  
    updatedData = {
      ...selectData,
      promotion: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "cancelation") {
    const featureId = value;
  
    // Ensure selectData.discount is an array or initialize it as an empty array
    const currentDiscount = selectData?.cancelation || [];
  
    const updatedFeatures = checked
      ? currentDiscount.includes(featureId) // Check for duplicates
        ? currentDiscount // If featureId is already in the array, return the array as is
        : [...currentDiscount, featureId] // If not, add the featureId to the array
      : currentDiscount.filter((id) => id !== featureId); // Remove the featureId if unchecked
  
    updatedData = {
      ...selectData,
      cancelation: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "languages") {
    const featureId = parseInt(value);
    const updatedFeatures = checked
      ? [...selectData.languages, featureId]
      : selectData.languages.filter((id) => id !== featureId);

    updatedData = {
      ...selectData,
      languages: updatedFeatures,
    };
  }
  else if (type === "checkbox" && name === "states") {
    const featureId = parseInt(value);
    
    const updatedFeatures = checked
      ? [...selectData?.states, featureId]
      : selectData?.states.filter((id) => id !== featureId);

    updatedData = {
      ...selectData,
      states: updatedFeatures,
    };
  }
  else {
    // alert("hmm")
    updatedData = {
      ...selectData,
      [name]: radio ? parseInt(value) : value,
    };
  }

  setSelectData(updatedData);
  sessionStorage.setItem(storageName, JSON.stringify(updatedData));
};
export const addMoreImageArray = [
  {
    id: 1,
    header: "Main picture",
    text: "3/4 Front",
    description:
      "A 3/4 front photo that stands out: it is the first one drivers see.",
      img:"https://getaround.com/packs/images/car/picture_uploader/front/large@2x-4415128ace9341612e79b9e9f4d1db9c.jpg"
  },
  {
    id: 2,
    header: "Additional pictures",

    text: "Side",
    description: "A side photo to give an idea of the size of your car.",
    img:"https://getaround.com/packs/images/car/picture_uploader/side/large@2x-f36d315a9e255eb77ef18250ff7461a7.jpg"

  },
  {
    id: 3,

    text: "3/4 Back",
    description: "A 3/4 back photo to complete the exterior overview.",
    img:"https://getaround.com/packs/images/car/picture_uploader/back/large@2x-9838bbfde9633e10e6eef514551c21c7.jpg"

  },

  {
    id: 4,

    text: "Interior",
    description:
      "An interior photo to help drivers picture themselves behind the wheel.",
      img:"https://getaround.com/packs/images/car/picture_uploader/interior/large@2x-e9c7fe1a04d5b00c72f10d131664d190.jpg"

  },
  {
    id: 5,

    text: "Extra",
    description: "Extra photos to highlight specifics (trunk, baby seat...).",

  },
];
export const formatDate =(inputDate) =>{
  const date = new Date(inputDate);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function validateDates(pickup, dropoff,dialogue ) {
  // Parse the dates from the strings
  const pickupDate = new Date(pickup);
  const dropoffDate = new Date(dropoff);

  // Compare the dates
  if (pickupDate < dropoffDate) {
      return "OK";
  } else {
      return "Error: Pickup date must be earlier than dropoff date.";
  }
}