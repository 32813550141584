import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const VehicleDemension = () => {
    const { selectDataCaseThree, setSelectDataCaseThree } = useContext(DataContext);

  return (
  
    <>
    <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
      <div class="c-text-section-heading c-mb-xs">List my car</div>
      <div class="c-text-title-md c-mb-lg">Vehicle dimensions & internal volume</div>
      <div class="c-mb-lg">
        <div class="cobalt-FormField" data-form-field-method="address_line1">
          <label class="cobalt-FormField__Label" for="car_address_line1">
          Usable volume
          </label>
          <div class="cobalt-TextField">
            <input
              class="cobalt-TextField__Input"
              type="text"
              name="usable_volume"
             value={selectDataCaseThree?.usable_volume}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Payload
          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="text"
              name="payload"
             value={selectDataCaseThree?.payload}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
          <div class="cobalt-FormField" data-form-field-method="address_line2">
          <label class="cobalt-FormField__Label" for="car_address_line2">
          Internal length

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="number"
              name="internal_length"
             value={selectDataCaseThree?.internal_length}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="postal_code">
          <label class="cobalt-FormField__Label" for="car_postal_code">
          Internal width

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="number"
              name="internal_width"
             value={selectDataCaseThree?.internal_width}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="city">
          <label class="cobalt-FormField__Label" for="car_city">
          Internal height

          </label>
          <div class="cobalt-TextField">
          <input
              class="cobalt-TextField__Input"
              type="number"
              name="internal_height"
             value={selectDataCaseThree?.internal_height}

              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
            />
          </div>
        </div>
       
      </div>
      <div class="cobalt-Note cobalt-Note--fullWidth">
        In order for you to receive payments, we use your billing address,
        phone number and email. You can edit them in your account details.
      </div>
    </div>
  </>
)
};

export default VehicleDemension;
